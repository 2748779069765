export interface Translation {
  [key: string]: string | number | boolean | Translation | Translation[];
}


export const loadTranslations = (modules: Record<string, unknown>) => {
  // Define static glob paths for all languages you support
  const translations: { [lang: string]: Translation } = {};
  
  Object.entries(modules).forEach(([path, module]) => {
      const match = path.match(/^\.\/(.*?)\/(.*)\.json$/);
      if (match) {
      const [, , filePath] = match;
      const key = filePath.replace(/\//g, '.');
      translations[key] = (module as { default: Translation }).default;
      }
  });
  
  return translations;
};

export const en = loadTranslations(import.meta.glob('./en/**/*.json', { eager: true }));
