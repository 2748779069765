import React from 'react';

import * as Sentry from '@sentry/react';
import type { ErrorInfo, ReactNode } from 'react';
import { Component } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import type { Primitive, Context } from '@sentry/types';
import type { UnregisterCallback } from 'history';

// @todo: check if this is helpful.
// We may not need this, because Sentry should automatically assign the owner
export enum ErrorBoundaryOwners {
  Analytics = 'hris-analytics-fe',
  Framework = 'contracts-framework-fe',
  ContractsCore = 'contracts-core',
  DeelCard = 'deel-card',
  SalaryInsights = 'salary-insights',
  EorOnboarding = 'eor-onboarding',
  EorContractManagement = 'eor-contract-management-compliance',
  Onboarding = 'onboarding',
  PayIns = 'pay-ins',
  PayOuts = 'pay-outs',
  Platform = 'platform',
  Mobile = 'mobile',
  none = 'none',
  Advance = 'advance-fe',
  Home = 'home-fe',
  Notifications = 'notifications-fe',
  Automation = 'hris-automation-fe',
  DeelAI = 'knowledge-fe',
  Verifications = 'verifications-fe',
  TimeOff = 'timeoff-fe',
  TimeTracking = 'timetracking-fe',
  EmbeddedPayroll = 'embedded-payroll',
  Transitions = 'transitions-team',
  Benefits = 'benefits-admin',
  Hris = 'hris-fe',
  PEO = 'peo',
  EmployeeExperience = 'employee-experiences-fe',
  // unknown is used pre-migration
  unknown = 'unknown',
}

export enum ErrorBoundaryIssueTypes {
  CrashingPage = 'crashing-page',
  CrashingComponent = 'crashing-component',
}

interface IBaseErrorBoundaryProps {
  owner: ErrorBoundaryOwners;
  children: ReactNode;
  fallback?: React.ReactNode | null;
  onError?: (error: Error) => void;
  issueType?: ErrorBoundaryIssueTypes | string;
  tags?: { [key: string]: Primitive };
  contextInfo?: Context;
  context?: string;
}

interface IErrorBoundaryWithRouterProps extends IBaseErrorBoundaryProps, RouteComponentProps {}

interface ErrorBoundaryState {
  error: Error | null;
}

// Base class with shared logic
class BaseErrorBoundary<P extends IBaseErrorBoundaryProps> extends Component<P, ErrorBoundaryState> {
  constructor(props: P) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Sentry.withScope((scope) => {
      const tags = {
        issue_type: this.props.issueType ?? ErrorBoundaryIssueTypes.CrashingPage,
        owner: this.props.owner,
        ...(this.props.tags ?? {}),
      };
      scope.setTags(tags);
      const additionalInfo: Context = { errorInfo, ...(this.props.contextInfo ?? {}) };
      scope.setContext(this.props.context ?? 'additional_info', additionalInfo);

      Sentry.captureException(error);
    });
  }

  render() {
    const { error } = this.state;

    if (error) {
      if (this.props.onError) {
        this.props.onError(error);
      }
      return this.props.fallback ? <>{this.props.fallback}</> : null;
    }

    return <>{this.props.children}</>;
  }
}

// ErrorBoundary with router
class ErrorBoundaryWithRouterClass extends BaseErrorBoundary<IErrorBoundaryWithRouterProps> {
  unlisten: UnregisterCallback | null = null;

  componentDidMount() {
    this.unlisten = this.props.history.listen(() => {
      if (this.state.error) {
        this.setState({ error: null });
      }
    });
  }

  componentWillUnmount() {
    if (this.unlisten) {
      this.unlisten();
    }
  }
}

class ErrorBoundary extends BaseErrorBoundary<IBaseErrorBoundaryProps> {}
const ErrorBoundaryWithRouter = withRouter(ErrorBoundaryWithRouterClass);

export { ErrorBoundaryWithRouter };
export default ErrorBoundary;
